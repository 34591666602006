import $ from 'jquery'

export default function () {
  var questions = $('.question-link');
  questions.click(function (e) {
    e.preventDefault();
     $($(this).data('question')).toggleClass('hidden');
     $(this).find('svg').toggleClass('arrow-rotate');
  });
}

import throttle from 'lodash/throttle';
import $ from 'jquery'

export default function () {

  $(document).ready(function () {

    $('.lf-team-card-link').click(function (e) {
      e.preventDefault();
      let toggleEl = $($(this).data('toggle'));

      //Check for open class and open card if it hasn't been otherwise close card.
      if (!$(this).hasClass('openCard')) {

        // close any open cards
        $.each($('.lf-team-card-link'), function () {
          $(this).parent().find('.card-content .lf-team-card-inner').addClass('hide');
          $(this).parent().find('.card-content').height(0);
          $(this).removeClass('openCard');
        });

        // open this card
        $(this).addClass("openCard");
        let cardContentHeight = $(this).parent().find('.card-content .lf-team-card-inner').height();
        //console.log(cardContentHeight);
        $(this).parent().find('.card-content').height(cardContentHeight);
        $(this).parent().find('.card-content .lf-team-card-inner').removeClass('hide');

      } else {

        // this original code doesn't work, the cards can't be opened a second time
        // $(this).parent().find('.card-content .lf-team-card-inner').addClass('hide');
        // $(this).parent().find('.card-content').height(0);

        // $(this).siblings('.card-content').find('.lf-team-card-inner').addClass('no-height');
        // $(this).removeClass("openCard");

        // so just close all open cards as above...
        $.each($('.lf-team-card-link'), function () {
          $(this).parent().find('.card-content .lf-team-card-inner').addClass('hide');
          $(this).parent().find('.card-content').height(0);
          $(this).removeClass('openCard');
        });

    }
  });
    $('.lf-close-team-card').click(function (e) {
      e.preventDefault();
      let cardToClose = $('#' + $(this).data('close-card'));
      console.log(cardToClose);
      cardToClose.parent().find('.card-content .lf-team-card-inner').addClass('hide')
      cardToClose.parent().find('.card-content').height(0);
      cardToClose.removeClass("openCard");
      $('.lf-team-listing-page-cards').parent().find('.card-content .lf-team-card-inner').addClass('hide')
      $('.lf-team-listing-page-cards').parent().find('.card-content').height(0);
    });
  });
}

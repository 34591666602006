import Defer from "./modules/defer"
// import Greeting from "./modules/hello"
// import Scrolling from "./modules/scrolling"
import Testimonials from './modules/testimonials'
import CategorySelect from './modules/category-select'
import StaffCards from './modules/staff-cards'
import TimeCapsuleCards from './modules/timecapsule-cards'
import NavSearch from './modules/nav-search'
import Tooltips from './modules/tooltips'
import ServiceSelect from './modules/service-select'
import FAQ from './modules/faq'
import slideNav from './modules/slide-nav'
import dashboardMenu from './modules/dashboard-menu'
import startPage from './modules/start-page'

const site = {
  /*
   * Initialises the site's modules.
   * Each module defines its own init function, this is just the glue.
   */
  init() {
    Defer()
    // Greeting()  // annoying lift ad in js console
    // Scrolling()  // annoying "show header when scrolling up"
    Testimonials()
    CategorySelect()
    StaffCards()
    TimeCapsuleCards()
    NavSearch()
    Tooltips()
    ServiceSelect()
    FAQ()
    slideNav()
    dashboardMenu()
    startPage()
  },
}

site.init()

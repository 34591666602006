import $ from 'jquery'

export default function() {
      setTimeout(function () {
        if ($('#category-select')) {
            $('#category-select').change(function (e) {
                window.location.href = window.location.protocol + '//' + window.location.host + $("#category-select option:selected").attr('value');
            });
        }
    }, 1000);
}

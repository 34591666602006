import $ from 'jquery'

export default function () {

  $(document).ready(function () {
    $('.service-table-checkboxes tr').click(function (event) {
      let clicked = $(this);
      $('.service-table-checkboxes tr').each(function (index, item) {
          $(this).removeClass('selected');
          $(this).find('.btn').text('Select'); // @todo needs to be marked for translation with gettext()
      });
      clicked.addClass('selected');
      clicked.find('.btn').text('Selected');

      $(this).find('td input[type=radio]').prop('checked', true);
    });
  });

}

import Swiper from 'swiper'
// import Swiper from 'swiper/js/swiper.js'

export default function () {
  var mySwiper = new Swiper('.swiper-container', {
    speed: 419,
    autoplay: {
      delay: 6737,
    },
    spaceBetween: 100,
    autoHeight: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    centeredSlides: true,
    setWrapperSize: 1200,
  });
}


const REFRESH_DELAY_MS = 5 * 60 * 1000

function updateMailStats() {
  const unreadCountEls = document.getElementsByClassName('unread-mail-count')
  const mailboxUsageEl = document.getElementById('mailbox-percent-usage')
  if (unreadCountEls.length) {
    fetch('/en/dashboard/mail-stats/')
      .then(res => res.json())
      .then(data => {
        for (const el of unreadCountEls) {
          el.innerText = data.unread
          el.parentNode.classList.remove('hidden')
        }
        if (data.usage && mailboxUsageEl) {
          mailboxUsageEl.innerText = data.usage
          mailboxUsageEl.parentNode.classList.remove('hidden')
        }
      })
      .then(() => {
        setTimeout(updateMailStats, REFRESH_DELAY_MS)
      })
      .catch(err => {
        console.error(err)
        for (const el of unreadCountEls) {
          el.innerText = 'error'
          el.parentNode.classList.remove('hidden')
        }
      })
  }
}

function updateYellowMailStats() {
  const mailCountEls = document.getElementsByClassName('yellow-mail-count')
  if (!mailCountEls.length) return
  fetch('/en/dashboard/yellow-mail-stats/')
    .then(res => res.json())
    .then(data => {
      if (data.yellow) {
        for (const el of mailCountEls) {
          el.innerText = data.yellow
          el.parentNode.classList.remove('hidden')
        }
      }
    })
    .then(() => {
      setTimeout(updateYellowMailStats, REFRESH_DELAY_MS)
    })
    .catch(err => {
      console.error(err)
      for (const el of mailCountEls) {
        el.innerText = 'error'
        el.parentNode.classList.remove('hidden')
      }
    })
}

export default function () {
  document.addEventListener('DOMContentLoaded', function() {
    updateMailStats()
    updateYellowMailStats()
  })
}


export default function () {
  let searchWrapper = document.querySelector('.search-field');
  let searchButton = document.querySelector('.lf-search-icon');
  let closeSearch = document.querySelector('.lf-close-search-icon');

  if (searchButton) {
    searchButton.addEventListener('click', function (e) {
      e.preventDefault();
      searchWrapper.classList.toggle('collapsed');
      searchWrapper.querySelector('input').focus();
    });
  }

  if (closeSearch) {
    closeSearch.addEventListener('click', function (e) {
      e.preventDefault();
      searchWrapper.classList.toggle('collapsed');
      searchWrapper.querySelector('input').value = '';
    });
  }


}


function getAncestorLink(el) {
console.log(el)
  while (!el.href) {
    console.log(el)
    el = el.parentElement
  }
  return el
}
function openLinkInSmallWindow(e) {
  var height = Math.round(screen.availHeight * 3 / 4)
  var width = Math.round(screen.availWidth / 3)
  var top = Math.round(screen.availHeight / 10)
  var left = screen.width - width - top
  let linkEl = getAncestorLink(e.target)
  window.open(
    linkEl.href,
    linkEl.target,
    //'height=800,width=600,top=50,left=50,menubar=0,toolbar=0'
    'height='+height+',width='+width+',top='+top+',left='+left+',menubar=0,toolbar=0'
  )
  e.preventDefault()
}

function openLinkInLargeWindow(e) {
  let linkEl = getAncestorLink(e.target)
  window.open(
    linkEl.href,
    linkEl.target
  )
  e.preventDefault()
}

export default function () {

  let dashboardMenu = document.querySelector('.dashboard-menu');
  let dashboardMenuBtn = document.querySelector('.dashboard-menu-btn');

  if (dashboardMenuBtn) {
    dashboardMenuBtn.addEventListener('click', function () {
      dashboardMenu.querySelector('.fluid-container').classList.toggle('h-0');
    });
  }

  for (let link of document.querySelectorAll('.dashboard-popup-link')) {
    link.onclick = openLinkInSmallWindow
  }
  for (let link of document.querySelectorAll('.dashboard-popup-link-large')) {
    link.onclick = openLinkInLargeWindow
  }

}


export default function () {
    /* Select all elements needed for nav controls */
    let navButton = document.querySelector('.hamburger');
    let navClose = document.querySelector('.close-nav');
    let slideNav = document.querySelector('.slide-nav');
    let slideNavWrapper = document.querySelector('.slide-nav-wrapper');

    // not used on login/logout pages
    if (!navButton) {
        return false
    }

    function closeNav() {
        slideNav.classList.remove('open');
        setTimeout(function () {
            slideNavWrapper.classList.remove('display-block');
            slideNavWrapper.classList.remove('slide-nav-overlay');
            document.querySelector('body').classList.add('overflow-hidden')
        }, 300);
    }

    //event listener for clicks on nav button
    navButton.addEventListener('click', function (e) {
        //Prevent default link action

        e.preventDefault();
        if (slideNavWrapper.classList.contains('display-block')) {
            closeNav();
        } else {
            //Use a class to toggle the nav open or closed
            slideNavWrapper.classList.add('display-block');
            setTimeout(function () {
                slideNav.classList.toggle('open');
                slideNavWrapper.classList.add('slide-nav-overlay');
                document.querySelector('body').classList.add('overflow-hidden')
            }, 100);
        }
    });

    //event listener for clicks on close button
    navClose.addEventListener('click', function (e) {
        e.preventDefault();
        closeNav();
    });

    /* Dropdown nav js */
    //Using all so that if there are more dropdowns they will work too
    let dropdownLinks = document.querySelectorAll('.mobile-dropdown-link');
    for (let link of dropdownLinks) {
        link.addEventListener('click', function (e) {
            //Toggle dropdown open class
            this.classList.toggle('mobile-dropdown-open');
        });
    }


    let mobileChildrenToggle = document.querySelectorAll('.mobile-menu-dropdown-toggle');

    //mobileChildrenToggle.forEach( toggle =>{
    for (let toggle of mobileChildrenToggle) {
        toggle.addEventListener('click', () =>{
            slideMobileChildren(toggle);
        })
    };

    function slideMobileChildren(toggle){
        let parent = toggle.parentElement;
        let mobileChildrenWrap = parent.querySelector('.mobile-children-wrap');
        let mobileChildrenWrapInner = parent.querySelector('.mobile-children-wrap > ul').offsetHeight + 15;

        if(!toggle.classList.contains('active')){
            toggle.classList.add('active');
            mobileChildrenWrap.style.height = `${mobileChildrenWrapInner}px`;
        }else{
            mobileChildrenWrap.style.height = '0px';
            toggle.classList.remove('active');

        }
    }

}




